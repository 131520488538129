import React, { useState, useEffect } from "react";
import "./DirectorCard.css";
import useIsMobile from "../../../custom-hooks/useIsMobile";

//COMPONENTS
import SectionHeader from "../SectionHeader/SectionHeader";

const DirectorCard = ({ image, title, text, info, director, data }) => {
  const [height, setHeight] = useState("350px");
  const [imageLoaded, setImageLoaded] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (imageLoaded === true && !isMobile) {
      const box = document.getElementById("director-card__box");
      setHeight(box.clientHeight);
    } else if (imageLoaded === true && isMobile) {
      setHeight("auto");
    } else {
      setHeight(0);
    }
    //eslint-disable-next-line
  }, [imageLoaded]);
  return (
    <div
      className="director-card__container"
      style={director === false ? { marginTop: 0 } : {}}
    >
      {director !== false && data !== undefined && (
        <SectionHeader
          title={data.sectionHeader.senseFronteres.elDirectorSection.title}
          text={data.sectionHeader.senseFronteres.elDirectorSection.subtitle}
          width="150px"
        />
      )}
      <div className="director-card__box-wrapper">
        <img
          src={image}
          alt=""
          className="director-card__image"
          style={{
            height: isMobile ? "auto" : height,
            width: !isMobile ? height : "100%",
          }}
          onLoad={() => {
            setImageLoaded(true);
          }}
        />
        <div className="director-card__box" id="director-card__box">
          <div className="director-card__box-title">{title}</div>
          <div
            className="director-card__box-text"
            style={
              director === true && !isMobile ? { lineHeight: "2.5rem" } : {}
            }
          >
            {text}
          </div>
          {director !== true && (
            <div
              className="director-card__box-info"
              style={
                director === false
                  ? { alignSelf: "flex-start", textAlign: "left", marginTop: 0 }
                  : {}
              }
            >
              {info}
            </div>
          )}
        </div>
      </div>
      {director === true && (
        <div
          className="director-card__box-info"
          style={
            director === false
              ? { alignSelf: "flex-start", textAlign: "left", marginTop: 0 }
              : {}
          }
        >
          {info}
        </div>
      )}
    </div>
  );
};

export default DirectorCard;
