import React, { useState, useEffect } from "react";
import "./AgendaItem.css";
import TicketsButton from "../../utils/TicketsButton/TicketsButton";
import AgendaDate from "../../utils/AgendaDate/AgendaDate";
const AgendaItem = ({
  date,
  title,
  location,
  available,
  buttonText,
  main,
  coral,
  link,
  nameGospelViu,
  nameGospelProject,
  nameGospelHOE,
  address,
  hora,
}) => {
  const [styles, setStyles] = useState({});
  useEffect(() => {
    if (coral === nameGospelViu) {
      setStyles({
        color: "#c61b1e",
      });
    } else if (coral === nameGospelProject) {
      setStyles({
        color: "#090c9b",
      });
    } else if (coral === nameGospelHOE) {
      setStyles({
        color: "#006400",
      });
    }
    //eslint-disable-next-line
  }, [coral]);
  return (
    <div className="agenda-item__container">
      <div className="agenda-item__left-wrapper">
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <AgendaDate buttonText={date} />
          <AgendaDate buttonText={hora} />
        </div>
        <div className="agenda-item__info-wrapper">
          {main === true ? (
            <div>
              <span className="agenda-item__title">{title}</span>,{" "}
              <span className="agenda-item__title-coral" style={styles}>
                {coral}
              </span>
            </div>
          ) : (
            <span className="agenda-item__title">{title}</span>
          )}
          <span className="agenda-item__location">{location}</span>
          <span className="agenda-item__location">{address}</span>
        </div>
      </div>
      <TicketsButton
        buttonText={buttonText}
        available={available}
        link={link}
      />
    </div>
  );
};

export default AgendaItem;
