import React, { useRef, useState, useEffect } from "react";
/*eslint-disable*/
import ReactPlayer from "react-player";
import "./ONG.css";
import useElementHeight from "../../../custom-hooks/useElementHeight";
import DirectorCard from "../../individual/DirectorCard/DirectorCard";
import SectionHeader from "../../individual/SectionHeader/SectionHeader";
import Footer from "../../individual/Footer/Footer";
import useIsMobile from "../../../custom-hooks/useIsMobile";
const ONG = ({ data }) => {
  const [videoIsTransformed, setVideoIsTransformed] = useState(false);
  const [videoUrl, setVideoUrl] = useState();
  const [logoUrl, setLogoUrl] = useState();
  const [directorImage, setDirectorImage] = useState();
  const leftRef = useRef(null);
  const height = useElementHeight(leftRef);
  const isMobile = useIsMobile();

  useEffect(() => {
    const transformations = "q_auto,f_auto";
    const imageTransformations =
      "q_auto,f_auto,fl_lossy,w_auto,c_scale,dpr_auto";
    const transformedVideo = data?.ong?.home?.photo[0]?.url.replace(
      `/upload/`,
      `/upload/${transformations}/`
    );
    setVideoUrl(transformedVideo);
    setVideoIsTransformed(true);
    const transformedLogo = data?.ong?.home?.logo[0]?.url.replace(
      `/upload/`,
      `/upload/${imageTransformations}/`
    );
    setLogoUrl(transformedLogo);
    const transformedDirectorImage =
      data?.ong?.colaborate?.image[0].url.replace(
        `/upload/`,
        `/upload/${imageTransformations}/`
      );
    setDirectorImage(transformedDirectorImage);
  }, [data]);

  return (
    <div className="ong__container">
      <div className="ong__home">
        <div className="ong__home__left" ref={leftRef}>
          <div className="ong__home__left__top-wrapper">
            {data !== undefined && (
              <img
                src={logoUrl}
                alt="logo-ong"
                style={{ objectFit: "cover" }}
              />
            )}
            {data !== undefined && (
              <p style={{ textAlign: "justify" }}>{data?.ong?.home?.text}</p>
            )}
          </div>
          {!isMobile && data !== undefined && (
            <div
              className="ong__home__button"
              onClick={() => {
                window.open(data?.ong?.home?.button?.link, "_blank");
              }}
            >
              {data?.ong?.home?.button?.text}
            </div>
          )}
        </div>
        <div
          className="ong__home__right"
          style={
            !isMobile
              ? { height: height }
              : {
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                  width: "100%",
                }
          }
        >
          {videoIsTransformed && (
            <ReactPlayer
              url={
                "https://www.youtube.com/watch?v=YP-ZXtmbfIQ&feature=youtu.be"
              }
              alt=""
              style={{ objectFit: "cover", borderRadius: "50px" }}
              width={"100%"}
              height={"100%"}
              controls
              config={{
                file: {
                  attributes: {
                    style: {
                      objectFit: "cover",
                      height: "100%",
                      width: "100%",
                    },
                  },
                },
              }}
            />
          )}
          {isMobile && data !== undefined && (
            <div
              className="ong__home__button"
              onClick={() => {
                window.open(data?.ong?.home?.button?.link, "_blank");
              }}
            >
              {data?.ong?.home?.button?.text}
            </div>
          )}
        </div>
      </div>
      <div className="ong__text-box__container">
        {data !== undefined && (
          <p className="ong__text-box__text">
            <span style={{ fontWeight: "bold" }}>
              {data?.ong?.banner?.span}
            </span>
            {data?.ong?.banner?.text}
          </p>
        )}
        <div className="ong__text-box__red-line"></div>
      </div>
      <div className="ong__collaboration__container">
        {(data?.sectionHeader?.colaborate?.title !== "" ||
          data?.sectionheader?.colaborate?.title !== undefined) && (
          <SectionHeader
            title={data?.sectionHeader?.ong?.colaborate?.title}
            text={data?.sectionHeader?.ong?.colaborate?.subtitle}
            width="150px"
          />
        )}
        <DirectorCard
          director={false}
          image={directorImage}
          title={data?.ong?.colaborate?.title}
          text={data?.ong?.colaborate?.text}
          info={data?.ong?.colaborate?.quote}
        />
      </div>
      {data !== undefined && (
        <Footer
          title={data?.senseFronteres?.footer?.title}
          navbar={data?.navbar}
          contact={data?.senseFronteres?.footer?.contact}
        />
      )}
    </div>
  );
};

export default ONG;
