import React, { useContext, useEffect, useState } from "react";

import { Link, useNavigate, useLocation } from "react-router-dom";

import { ArrowContext } from "../../../context/carruselContext";
import { GiHamburgerMenu } from "react-icons/gi";
import useIsMobile from "../../../custom-hooks/useIsMobile";
import { GrClose } from "react-icons/gr";

//HOOKS
import useScroll from "../../../custom-hooks/useScroll";

//COMPONENTS
import "./Navbar.css";

//IMAGES
import logo from "../../../assets/logos/logoNavbar.png";

const Navbar = ({ items }) => {
  const [displayBackground, setDisplayBackground] = useState(false);
  const [displayDropdownMenu, setDisplayDropdownMenu] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const scrollY = useScroll();
  const { setArrowCounter } = useContext(ArrowContext);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (
      location.pathname === "/agenda" ||
      location.pathname === "/ong" ||
      location.pathname === "/"
    ) {
      setDisplayBackground(true);
    } else if (
      location.pathname !== "/agenda" &&
      location.pathname !== "/ong" &&
      scrollY === 0
    ) {
      setDisplayBackground(false);
    } else if (scrollY !== 0) {
      setDisplayBackground(true);
    }
  }, [location.pathname, scrollY]);
  return (
    <div
      className={
        displayBackground
          ? "navbar__container"
          : "navbar__container blur-background"
      }
    >
      <div
        className="navbar__left-wrapper"
        onClick={() => {
          navigate("/");
        }}
      >
        <img src={logo} alt="" style={{ backgroundColor: "white" }} />
        <div className="navbar__logo-text-wrapper">
          <span className="navbar__logo-text">{items?.title?.p1}</span>
          <span className="navbar__logo-text">{items?.title?.p2}</span>
        </div>
      </div>
      {isMobile === false && (
        <div className="navbar__right-wrapper">
          <Link
            to={"/"}
            className="navbar__link"
            onClick={() => {
              setArrowCounter(0);
            }}
          >
            {items?.home}
          </Link>
          <Link
            to={"/gospel-viu"}
            className="navbar__link"
            onClick={() => {
              setArrowCounter(0);
            }}
          >
            {items?.gospelViu}
          </Link>
          <Link
            to={"/gospel-project"}
            className="navbar__link"
            onClick={() => {
              setArrowCounter(0);
            }}
          >
            {items?.gospelProject}
          </Link>
          <Link
            to={"/gospel-heaven-on-earth"}
            className="navbar__link"
            onClick={() => {
              setArrowCounter(0);
            }}
          >
            {items?.gospelHOE}
          </Link>
          <Link
            to={"/ong"}
            className="navbar__link"
            onClick={() => {
              setArrowCounter(0);
            }}
          >
            {items?.ong}
          </Link>
          <Link
            to={"/agenda"}
            className="navbar__link"
            onClick={() => {
              setArrowCounter(0);
            }}
          >
            {items?.agenda}
          </Link>
        </div>
      )}
      {isMobile === true && displayDropdownMenu === false && (
        <GiHamburgerMenu
          color="white"
          onClick={() => {
            setDisplayDropdownMenu(!displayDropdownMenu);
          }}
        />
      )}
      {isMobile === true && displayDropdownMenu === true && (
        <GrClose
          color="white"
          onClick={() => {
            setDisplayDropdownMenu(!displayDropdownMenu);
          }}
        />
      )}
      {isMobile === true && displayDropdownMenu === true && (
        <div className="navbar__dropdown-menu__container">
          {" "}
          <Link
            to={"/"}
            className="navbar__link"
            onClick={() => {
              setArrowCounter(0);
              setDisplayDropdownMenu(false);
            }}
          >
            {items?.home}
          </Link>
          <Link
            to={"/gospel-viu"}
            className="navbar__link"
            onClick={() => {
              setArrowCounter(0);
              setDisplayDropdownMenu(false);
            }}
          >
            {items.gospelViu}
          </Link>
          <Link
            to={"/gospel-project"}
            className="navbar__link"
            onClick={() => {
              setArrowCounter(0);
              setDisplayDropdownMenu(false);
            }}
          >
            {items?.gospelProject}
          </Link>
          <Link
            to={"/gospel-heaven-on-earth"}
            className="navbar__link"
            onClick={() => {
              setArrowCounter(0);
              setDisplayDropdownMenu(false);
            }}
          >
            {items?.gospelHOE}
          </Link>
          <Link
            to={"/ong"}
            className="navbar__link"
            onClick={() => {
              setArrowCounter(0);
              setDisplayDropdownMenu(false);
            }}
          >
            {items?.ong}
          </Link>
          <Link
            to={"/agenda"}
            className="navbar__link"
            onClick={() => {
              setArrowCounter(0);
              setDisplayDropdownMenu(false);
            }}
          >
            {items?.agenda}
          </Link>
        </div>
      )}
    </div>
  );
};

export default Navbar;
