import React from "react";
import "./AgendaFilter.css";
import AgendaFilterButton from "../../utils/AgendaFilterButton/AgendaFilterButton";
const AgendaFilter = ({ items, setAgendaFilter, agendaFilter }) => {
  return (
    <div className="agenda-filter__container">
      <AgendaFilterButton
        buttonText={items?.filter1}
        setAgendaFilter={setAgendaFilter}
        id={0}
        agendaFilter={agendaFilter}
      />
      <AgendaFilterButton
        buttonText={items?.filter2}
        setAgendaFilter={setAgendaFilter}
        id={1}
        agendaFilter={agendaFilter}
      />
      <AgendaFilterButton
        buttonText={items?.filter3}
        setAgendaFilter={setAgendaFilter}
        id={2}
        agendaFilter={agendaFilter}
      />
      <AgendaFilterButton
        buttonText={items?.filter4}
        setAgendaFilter={setAgendaFilter}
        id={3}
        agendaFilter={agendaFilter}
      />
    </div>
  );
};

export default AgendaFilter;
