import React, { createContext, useState } from "react";

// Crea el context
const NavbarContext = createContext();

// Crea el component proveïdor
const NavbarContextProvider = ({ children }) => {
  const [navbarBackground, setNavbarBackground] = useState(0);

  return (
    <NavbarContext.Provider value={{ navbarBackground, setNavbarBackground }}>
      {children}
    </NavbarContext.Provider>
  );
};

export { NavbarContext, NavbarContextProvider };
