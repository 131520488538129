import React, { useContext, useState, useEffect, useRef } from "react";
import "./Carusel.css";
import CaruselCard from "../CaruselCard/CaruselCard";
import { ArrowContext } from "../../../context/carruselContext";
import Popup from "../Popup/Popup";
import useIsMobile from "../../../custom-hooks/useIsMobile";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
const Carusel = ({ images, data }) => {
  const [width, setWidth] = useState(null);
  const [displayPopup, setDisplayPopup] = useState(false);
  const [image1, setImage1] = useState();
  const [image2, setImage2] = useState();
  const [image3, setImage3] = useState();
  const [image4, setImage4] = useState();
  const [image5, setImage5] = useState();
  const [image6, setImage6] = useState();
  const [image7, setImage7] = useState();
  const [image8, setImage8] = useState();
  const [image9, setImage9] = useState();
  const [image10, setImage10] = useState();
  const [image11, setImage11] = useState();
  const [image12, setImage12] = useState();
  const [ubicacio1, setUbicacio1] = useState();
  const [ubicacio2, setUbicacio2] = useState();
  const [ubicacio3, setUbicacio3] = useState();
  const [ubicacio4, setUbicacio4] = useState();
  const [ubicacio5, setUbicacio5] = useState();
  const [ubicacio6, setUbicacio6] = useState();
  const [ubicacio7, setUbicacio7] = useState();
  const [ubicacio8, setUbicacio8] = useState();
  const [ubicacio9, setUbicacio9] = useState();
  const [ubicacio10, setUbicacio10] = useState();
  const [ubicacio11, setUbicacio11] = useState();
  const [ubicacio12, setUbicacio12] = useState();
  const [titol1, setTitol1] = useState();
  const [titol2, setTitol2] = useState();
  const [titol3, setTitol3] = useState();
  const [titol4, setTitol4] = useState();
  const [titol5, setTitol5] = useState();
  const [titol6, setTitol6] = useState();
  const [titol7, setTitol7] = useState();
  const [titol8, setTitol8] = useState();
  const [titol9, setTitol9] = useState();
  const [titol10, setTitol10] = useState();
  const [titol11, setTitol11] = useState();
  const [titol12, setTitol12] = useState();

  const [popupArray, setPopupArray] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [mobileIndex, setMobileIndex] = useState(0);
  //eslint-disable-next-line
  const [offsetWidth, setOffsetWidth] = useState(width - window.innerWidth);
  //eslint-disable-next-line
  const [leftPosition, setLeftPosition] = useState(0);
  const { arrowCounter } = useContext(ArrowContext);
  const isMobile = useIsMobile();
  useEffect(() => {
    setImage1(images?.projecte1?.image[0]?.url);
    setImage2(images?.projecte2?.image[0]?.url);
    setImage3(images?.projecte3?.image[0]?.url);
    setImage4(images?.projecte4?.image[0]?.url);
    setImage5(images?.projecte5?.image[0]?.url);
    setImage6(images?.projecte6?.image[0]?.url);
    setImage7(images?.projecte7?.image[0]?.url);
    setImage8(images?.projecte8?.image[0]?.url);
    setImage9(images?.projecte9?.image[0]?.url);
    setImage10(images?.projecte10?.image[0]?.url);
    setImage11(images?.projecte11?.image[0]?.url);
    setImage12(images?.projecte12?.image[0]?.url);
    setTitol1(images?.projecte1?.titol);
    setTitol2(images?.projecte2?.titol);
    setTitol3(images?.projecte3?.titol);
    setTitol4(images?.projecte4?.titol);
    setTitol5(images?.projecte5?.titol);
    setTitol6(images?.projecte6?.titol);
    setTitol7(images?.projecte7?.titol);
    setTitol8(images?.projecte8?.titol);
    setTitol9(images?.projecte9?.titol);
    setTitol10(images?.projecte10?.titol);
    setTitol11(images?.projecte11?.titol);
    setTitol12(images?.projecte12?.titol);
    setUbicacio1(images?.projecte1?.ubicacio);
    setUbicacio2(images?.projecte2?.ubicacio);
    setUbicacio3(images?.projecte3?.ubicacio);
    setUbicacio4(images?.projecte4?.ubicacio);
    setUbicacio5(images?.projecte5?.ubicacio);
    setUbicacio6(images?.projecte6?.ubicacio);
    setUbicacio7(images?.projecte7?.ubicacio);
    setUbicacio8(images?.projecte8?.ubicacio);
    setUbicacio9(images?.projecte9?.ubicacio);
    setUbicacio10(images?.projecte10?.ubicacio);
    setUbicacio11(images?.projecte11?.ubicacio);
    setUbicacio12(images?.projecte12?.ubicacio);
  }, [images]); //Palau de la Música Catalana
  useEffect(() => {
    if (arrowCounter === 1) {
      setLeftPosition((prev) => prev - (offsetWidth + 130 / 2) * 1);
    }
    if (arrowCounter === 0) {
      setLeftPosition(0);
    }
    //eslint-disable-next-line
  }, [arrowCounter]);
  useEffect(() => {
    const objecteInicial = images;
    // Crear un array buit per emmagatzemar les dades dels projectes
    const arrayDeDades = [];

    // Recórrer l'objecte inicial
    for (const key in objecteInicial) {
      // Verificar si l'objecte té la propietat "image" i si té elements dins
      if (objecteInicial[key].image && objecteInicial[key].image.length > 0) {
        // Crear un objecte amb les dades del projecte i afegir-lo a l'array
        const projecte = {
          url: objecteInicial[key].image[0].url,
          ubicacio: objecteInicial[key].ubicacio,
          titol: objecteInicial[key].titol,
        };
        arrayDeDades.push(projecte);
      }
    }
    setPopupArray(arrayDeDades);
  }, [images]);

  const handleCaruselMobileClickRight = () => {
    if (mobileIndex >= 0 && mobileIndex < popupArray.length - 1) {
      setMobileIndex((prev) => prev + 1);
    } else return;
  };
  const handleCaruselMobileClickLeft = () => {
    if (mobileIndex > 0) {
      setMobileIndex((prev) => prev - 1);
    }
  };
  useEffect(() => {
    if (displayPopup === true) {
      document.body.style.overflow = "hidden";
    } else if (displayPopup === false) {
      document.body.style.overflow = "auto";
    }
  }, [displayPopup]);
  const containerRef = useRef(null); // Definim el ref

  useEffect(() => {
    const handleResize = (entries) => {
      for (let entry of entries) {
        setWidth(entry.contentRect.width);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        //eslint-disable-next-line
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);
  const calculatedRowGap = `${width * 0.04}px`;

  return (
    <>
      <div
        className="carusel__container"
        ref={containerRef}
        style={
          !isMobile
            ? {
                position: "relative",
                rowGap: calculatedRowGap,
              }
            : {
                width: "100%",
                height: "250px",
              }
        }
        id="container"
      >
        {!isMobile && (
          <>
            {image1 !== "" && image1 !== undefined && (
              <CaruselCard
                image={image1}
                location={ubicacio1}
                title={titol1}
                setDisplayPopup={setDisplayPopup}
                setCurrentIndex={setCurrentIndex}
                index={0}
                width={width}
              />
            )}
            {image2 !== "" && image2 !== undefined && (
              <CaruselCard
                image={image2}
                location={ubicacio2}
                title={titol2}
                setDisplayPopup={setDisplayPopup}
                setCurrentIndex={setCurrentIndex}
                index={1}
                width={width}
              />
            )}
            {image3 !== "" && image3 !== undefined && (
              <CaruselCard
                image={image3}
                location={ubicacio3}
                title={titol3}
                setDisplayPopup={setDisplayPopup}
                setCurrentIndex={setCurrentIndex}
                index={2}
                width={width}
              />
            )}
            {image4 !== "" && image4 !== undefined && (
              <CaruselCard
                image={image4}
                location={ubicacio4}
                title={titol4}
                setDisplayPopup={setDisplayPopup}
                setCurrentIndex={setCurrentIndex}
                index={3}
                width={width}
              />
            )}
            {image5 !== "" && image5 !== undefined && (
              <CaruselCard
                image={image5}
                location={ubicacio5}
                title={titol5}
                setDisplayPopup={setDisplayPopup}
                setCurrentIndex={setCurrentIndex}
                index={4}
                width={width}
              />
            )}
            {image6 !== "" && image6 !== undefined && (
              <CaruselCard
                image={image6}
                location={ubicacio6}
                title={titol6}
                setDisplayPopup={setDisplayPopup}
                setCurrentIndex={setCurrentIndex}
                index={5}
                width={width}
              />
            )}
            {image7 !== "" && image7 !== undefined && (
              <CaruselCard
                image={image7}
                location={ubicacio7}
                title={titol7}
                setDisplayPopup={setDisplayPopup}
                setCurrentIndex={setCurrentIndex}
                index={6}
                width={width}
              />
            )}
            {image8 !== "" && image8 !== undefined && (
              <CaruselCard
                image={image8}
                location={ubicacio8}
                title={titol8}
                setDisplayPopup={setDisplayPopup}
                setCurrentIndex={setCurrentIndex}
                index={7}
                width={width}
              />
            )}
            {image9 !== "" && image9 !== undefined && (
              <CaruselCard
                image={image9}
                location={ubicacio9}
                title={titol9}
                setDisplayPopup={setDisplayPopup}
                setCurrentIndex={setCurrentIndex}
                index={8}
                width={width}
              />
            )}
            {image10 !== "" && image10 !== undefined && (
              <CaruselCard
                image={image10}
                location={ubicacio10}
                title={titol10}
                setDisplayPopup={setDisplayPopup}
                setCurrentIndex={setCurrentIndex}
                index={9}
                width={width}
              />
            )}
            {image11 !== "" && image11 !== undefined && (
              <CaruselCard
                image={image11}
                location={ubicacio11}
                title={titol11}
                setDisplayPopup={setDisplayPopup}
                setCurrentIndex={setCurrentIndex}
                index={10}
                width={width}
              />
            )}
            {image12 !== "" && image12 !== undefined && (
              <CaruselCard
                image={image12}
                location={ubicacio12}
                title={titol12}
                setDisplayPopup={setDisplayPopup}
                setCurrentIndex={setCurrentIndex}
                index={11}
                width={width}
              />
            )}
          </>
        )}
        {isMobile && (
          <>
            {popupArray.length >= 1 && (
              <CaruselCard
                image={popupArray[mobileIndex].url}
                location={popupArray[mobileIndex].ubicacio}
                title={popupArray[mobileIndex].titol}
                setDisplayPopup={setDisplayPopup}
                setCurrentIndex={setCurrentIndex}
                index={mobileIndex}
              />
            )}
            <div
              className="section-header__button"
              style={
                arrowCounter === 1
                  ? {
                      backgroundColor: "var(--ghospel-red)",
                      position: "absolute",
                      left: "1.5rem",
                      top: "50%",
                    }
                  : {
                      backgroundColor: "var(--ghospel-gray)",
                      position: "absolute",
                      left: "1.5rem",
                      top: "50%",
                    }
              }
            >
              <MdKeyboardArrowLeft
                size={30}
                onClick={() => {
                  handleCaruselMobileClickLeft();
                }}
              />
            </div>
            <div
              className="section-header__button"
              style={
                arrowCounter === 1
                  ? {
                      backgroundColor: "var(--ghospel-gray)",
                      position: "absolute",
                      right: "1.5rem",
                      top: "50%",
                    }
                  : {
                      backgroundColor: "var(--ghospel-red)",
                      position: "absolute",
                      right: "1.5rem",
                      top: "50%",
                    }
              }
            >
              <MdKeyboardArrowRight
                size={30}
                onClick={() => {
                  handleCaruselMobileClickRight();
                }}
              />
            </div>
          </>
        )}
      </div>
      {displayPopup && (
        <Popup
          images={popupArray}
          setDisplayPopup={setDisplayPopup}
          currentIndex={currentIndex}
        />
      )}
    </>
  );
};

export default Carusel;
