import React, { useEffect } from "react";
import "./Footer.css";
import useIsMobile from "../../../custom-hooks/useIsMobile";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
const Footer = ({ title, contact, navbar }) => {
  const isMobile = useIsMobile();
  useEffect(() => {
    const loadScript = () => {
      const script = document.createElement("script");
      script.src = "https://cdn.iubenda.com/iubenda.js";
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    };

    loadScript();
  }, []);

  return (
    <div className="footer__container">
      <div className="footer__title-column">
        <span className="footer__column-main-title">{title}</span>
      </div>
      {isMobile && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="footer__column">
            <span className="footer__column-title">Contacte</span>
            <div className="footer__column-items-wrapper">
              <span>{contact?.phone}</span>
              <span>{contact?.email}</span>
              <span>{contact?.location}</span>
            </div>
          </div>
          <div className="footer__column">
            <span className="footer__column-title">
              Política privacitat i cookies
            </span>
            <div className="footer__column-items-wrapper">
              <a
                href="https://www.iubenda.com/privacy-policy/42077964"
                className="iubenda-item"
                title="Política de Privacidad"
              >
                Política de Privacitat
              </a>
              <a
                href="https://www.iubenda.com/privacy-policy/42077964/cookie-policy"
                class="iubenda-item"
                title="Política de Cookies"
              >
                Política de Cookies
              </a>
            </div>
          </div>
          <div className="footer__column">
            <span className="footer__column-title" style={{ textAlign: "end" }}>
              Segueix-nos
            </span>
            <div className="footer__column-icons-wrapper">
              <FaFacebook
                size={30}
                color="white"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(contact?.facebook, "_blank");
                }}
              />
              <FaInstagram
                size={30}
                color="white"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(contact?.links?.instagram, "_blank");
                }}
              />
              <FaYoutube
                size={30}
                color="white"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(contact?.youtube, "_blank");
                  console.log(contact);
                }}
              />
            </div>
          </div>
        </div>
      )}
      {!isMobile && (
        <>
          <div className="footer__column">
            <span className="footer__column-title">Menu</span>
            <div className="footer__column-items-wrapper">
              <Link to={"/gospel-viu"}>{navbar?.gospelViu}</Link>
              <Link to={"gospel-project"}>{navbar?.gospelProject}</Link>
              <Link to={"/gospel-heaven-on-earth"}>{navbar?.gospelHOE}</Link>
              <Link to={"/ong"}>{navbar?.ong}</Link>
              <Link to={"/agenda"}>{navbar?.agenda}</Link>
            </div>
          </div>
          <div className="footer__column">
            <span className="footer__column-title">Contacte</span>
            <div className="footer__column-items-wrapper">
              <span>{contact?.phone}</span>
              <span>{contact?.email}</span>
              <span>{contact?.location}</span>
            </div>
          </div>
          <div className="footer__column">
            <span className="footer__column-title">
              Política privacitat i cookies
            </span>
            <div className="footer__column-items-wrapper">
              <a
                href="https://www.iubenda.com/privacy-policy/42077964"
                className="iubenda-item"
                title="Política de Privacidad"
              >
                Política de Privacitat
              </a>
              <a
                href="https://www.iubenda.com/privacy-policy/42077964/cookie-policy"
                class="iubenda-item"
                title="Política de Cookies"
              >
                Política de Cookies
              </a>
            </div>
          </div>
          <div className="footer__column">
            <span className="footer__column-title">Segueix-nos</span>
            <div className="footer__column-icons-wrapper">
              <FaFacebook
                size={30}
                color="white"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(contact?.facebook, "_blank");
                }}
              />
              <FaInstagram
                size={30}
                color="white"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(contact?.links?.instagram, "_blank");
                }}
              />
              <FaYoutube
                size={30}
                color="white"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(contact?.youtube, "_blank");
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Footer;
