import React, { useContext } from "react";
/* eslint-disable */
import "./SectionHeader.css";
import { SectionHeaderBorder } from "./SectionHeaderStyled";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { ArrowContext } from "../../../context/carruselContext";
import useIsMobile from "../../../custom-hooks/useIsMobile";
const SectionHeader = React.forwardRef(
  ({ title, text, width, carrusel }, ref) => {
    const { setArrowCounter, arrowCounter } = useContext(ArrowContext);
    const isMobile = useIsMobile();

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "flex-end",
        }}
      >
        <div ref={ref} className="section-header__container">
          {title !== undefined && (
            <p className="section-header__title">{title}</p>
          )}
          {width !== undefined && (
            <SectionHeaderBorder
              width={width}
              margin={text === undefined}
            ></SectionHeaderBorder>
          )}
          {text !== undefined && <p className="section-header__text">{text}</p>}
        </div>
        {/* {carrusel === true && !isMobile && (
          <div className="section-header__button-wrapper">
            <div
              className="section-header__button"
              style={
                arrowCounter === 1
                  ? { backgroundColor: "var(--ghospel-red)" }
                  : { backgroundColor: "var(--ghospel-gray)" }
              }
            >
              <MdKeyboardArrowLeft
                size={30}
                onClick={() => {
                  setArrowCounter(0);
                }}
              />
            </div>
            <div
              className="section-header__button"
              style={
                arrowCounter === 1
                  ? { backgroundColor: "var(--ghospel-gray)" }
                  : { backgroundColor: "var(--ghospel-red)" }
              }
            >
              <MdKeyboardArrowRight
                size={30}
                onClick={() => {
                  setArrowCounter(1);
                }}
              />
            </div>
          </div>
        )} */}
      </div>
    );
  }
);

export default SectionHeader;
