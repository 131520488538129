import { useState, useEffect } from "react";
import RoutesComp from "./components/RoutesComp";

function App() {
  const [data, setData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://gospel-server-6a342b613ed1.herokuapp.com/"
        );

        if (response.ok) {
          const serverData = await response.json();
          setData(serverData[0].data);
        } else {
          console.error("Error fetching data from the API");
        }
      } catch (error) {
        console.error("Error fetching data from the API", error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <RoutesComp data={data} setData={setData} />
    </>
  );
}

export default App;
