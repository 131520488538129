/* eslint-disable*/
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import useIsMobile from "../custom-hooks/useIsMobile.js";
import Home from "./screens/Home/Home";
import Carusel from "./individual/Carusel/Carusel";
import Agenda from "./screens/Agenda/Agenda";
import DefaultCoral from "./screens/DefaultCoral/DefaultCoral";
import Footer from "./individual/Footer/Footer";
import ONG from "./screens/ONG/ONG.jsx";
import Navbar from "./individual/Navbar/Navbar.jsx";
import SectionHeader from "./individual/SectionHeader/SectionHeader.jsx";
import ScrollToTop from "./utils/ScrollToTop.js";
const RoutesComp = ({ data }) => {
  const [agendaFilter, setAgendaFilter] = useState(0);
  const [showedAgendaArray, setShowedAgendaArray] = useState([]);
  const [agendaArray1, setAgendaArray1] = useState([]);
  const [agendaArray2, setAgendaArray2] = useState([]);
  const [agendaArray3, setAgendaArray3] = useState([]);
  const isMobile = useIsMobile();
  const [agendaArray0, setAgendaArray0] = useState([]);
  const addNomProperty = (array, nom) => {
    return array.map((item) => {
      return { ...item, nom };
    });
  };
  const customDateComparator = (a, b) => {
    const [dayA, monthA, yearA] = a.date.split("/").map(Number);
    const [dayB, monthB, yearB] = b.date.split("/").map(Number);

    if (yearA !== yearB) {
      return yearA - yearB;
    }
    if (monthA !== monthB) {
      return monthA - monthB;
    }
    return dayA - dayB;
  };
  useEffect(() => {
    if (agendaFilter === 1) {
      setShowedAgendaArray(agendaArray2);
    } else if (agendaFilter === 2) {
      setShowedAgendaArray(agendaArray3);
    } else if (agendaFilter === 3) {
      setShowedAgendaArray(agendaArray1);
    } else if (agendaFilter === 0) {
      // Combina els tres arrays en un únic array
      setShowedAgendaArray(agendaArray0);
    }
  }, [agendaFilter]);

  useEffect(() => {
    const array1 = data?.agenda?.gospelHOE || [];
    const nomArray1 = data?.navbar?.gospelHOE || "";

    let array1WithNom = addNomProperty(array1, nomArray1);
    array1WithNom.sort(customDateComparator);
    setAgendaArray1(array1WithNom);

    const array2 = data?.agenda?.gospelViu || [];
    const nomArray2 = data?.navbar?.gospelViu || "";
    let array2WithNom = addNomProperty(array2, nomArray2);
    array2WithNom.sort(customDateComparator);
    setAgendaArray2(array2WithNom);

    const array3 = data?.agenda?.gospelProject || [];
    const nomArray3 = data?.navbar?.gospelProject || "";
    let array3WithNom = addNomProperty(array3, nomArray3);
    array3WithNom.sort(customDateComparator);
    setAgendaArray3(array3WithNom);
  }, [data]);
  useEffect(() => {
    const array1 = data?.agenda?.gospelHOE || [];
    const nomArray1 = data?.navbar?.gospelHOE || "";
    const array2 = data?.agenda?.gospelViu || [];
    const nomArray2 = data?.navbar?.gospelViu || "";
    let array2WithNom = addNomProperty(array2, nomArray2);
    let array1WithNom = addNomProperty(array1, nomArray1);
    const array3 = data?.agenda?.gospelProject || [];
    const nomArray3 = data?.navbar?.gospelProject || "";
    let array3WithNom = addNomProperty(array3, nomArray3);
    // Creem el mergedArray després d'ordenar els subarrays individuals
    const mergedArray = [...array1WithNom, ...array2WithNom, ...array3WithNom];
    mergedArray.sort(customDateComparator);

    setAgendaArray0(mergedArray);
    setShowedAgendaArray(mergedArray);
  }, [data]);
  return (
    <Router>
      <ScrollToTop />
      <Navbar items={data?.navbar} />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Home directorCard={true} data={data} />
              <div
                style={
                  isMobile === false
                    ? {
                        padding: "var(--padding-full)",
                        paddingBottom: "80px",
                      }
                    : {
                        padding: "2rem",
                        paddingBottom: "30px",
                        marginTop: "50px",
                      }
                }
              >
                {data !== undefined && (
                  <SectionHeader
                    title={
                      data?.sectionHeader?.senseFronteres?.projectesRealitzats
                        ?.title
                    }
                    subtitle={
                      data?.sectionHeader?.senseFronteres?.projectesRealitzats
                        ?.subtitle
                    }
                    carrusel={true}
                    width={"300px"}
                  />
                )}
              </div>
              <div
                style={
                  !isMobile
                    ? {
                        paddingLeft: "var(--padding-left)",
                      }
                    : { position: "relative" }
                }
              >
                {data !== undefined && (
                  <Carusel
                    images={data?.senseFronteres?.projectesRealitzats}
                    data={data}
                  />
                )}
              </div>
              <div
                style={
                  !isMobile
                    ? { padding: "var(--padding-full)" }
                    : { padding: "2rem", marginTop: "120px" }
                }
              >
                <SectionHeader
                  title={data?.sectionHeader?.agenda?.title}
                  text={data?.sectionHeader?.agenda?.subtitle}
                  width={"150px"}
                />
                <Agenda
                  data={showedAgendaArray}
                  main={true}
                  filter={data?.agenda?.filter}
                  setAgendaFilter={setAgendaFilter}
                  agendaFilter={agendaFilter}
                  nameGospelViu={data?.navbar?.gospelViu}
                  nameGospelProject={data?.navbar?.gospelProject}
                  nameGospelHOE={data?.navbar?.gospelHOE}
                />
              </div>
              {data !== undefined && (
                <Footer
                  title={data?.senseFronteres?.footer?.title}
                  contact={data?.senseFronteres?.footer?.contact}
                  navbar={data?.navbar}
                />
              )}
            </>
          }
        />
        <Route
          path="/gospel-viu"
          element={
            <>
              {data !== undefined && (
                <DefaultCoral
                  logo={data?.gospelViu?.trajectoria?.logo}
                  title={data?.navbar?.gospelViu}
                  homeSubtitle={data?.gospelViu?.home?.subtitle}
                  section={data?.gospelViu?.trajectoria?.boxTitle}
                  textBox={data?.gospelViu?.trajectoria?.boxText}
                  icon1Info={data?.gospelViu?.trajectoria?.boxIconText1}
                  icon3Info={data?.gospelViu?.trajectoria?.boxIconText3}
                  icon2Info={data?.gospelViu?.trajectoria?.boxIconText2}
                  icon4Info={data?.gospelViu?.trajectoria?.boxIconText4}
                  sectionHeaderTrajectoriaTitle={
                    data?.sectionHeader?.gospelViu?.trajectoria?.title
                  }
                  sectionHeaderTrajectoriaSubtitle={
                    data?.sectionHeader?.gospelViu?.trajectoria?.subtitle
                  }
                  boxImages={data?.gospelViu?.trajectoria?.images}
                  sectionHeaderProjectesRealitzatsTitle={
                    data?.sectionHeader?.gospelViu?.projectesRealitzats?.title
                  }
                  sectionHeaderProjectesRealitzatsSubtitle={
                    data?.sectionHeader?.gospelViu?.projectesRealitzats
                      ?.subtitle
                  }
                  sectionHeaderAgendaTitle={
                    data?.sectionHeader?.gospelViu?.agenda?.title
                  }
                  sectionHeaderAgendaSubtitle={
                    data?.sectionHeader?.gospelViu?.agenda?.subtitle
                  }
                  agendaData={agendaArray2}
                  defaultCoral={true}
                  width={360 * 5 + 40 * 4 + 130}
                  images={data?.gospelViu?.projectesRealitzats}
                  homeImages={data?.gospelViu?.home?.images}
                  filter={data?.agenda?.filter}
                />
              )}
              {data !== undefined && (
                <Footer
                  title={data?.gospelViu?.footer?.title}
                  contact={data?.gospelViu?.footer?.contact}
                  navbar={data?.navbar}
                />
              )}
            </>
          }
        />
        <Route
          path="/gospel-project"
          element={
            <>
              {data !== undefined && (
                <DefaultCoral
                  logo={data?.gospelProject?.trajectoria?.logo}
                  title={data?.navbar?.gospelProject}
                  homeSubtitle={data?.gospelProject?.home?.subtitle}
                  section={data?.gospelProject?.trajectoria?.boxTitle}
                  textBox={data?.gospelProject?.trajectoria?.boxText}
                  icon1Info={data?.gospelProject?.trajectoria?.boxIconText1}
                  icon3Info={data?.gospelProject?.trajectoria?.boxIconText3}
                  icon2Info={data?.gospelProject?.trajectoria?.boxIconText2}
                  icon4Info={data?.gospelProject?.trajectoria?.boxIconText4}
                  sectionHeaderTrajectoriaTitle={
                    data?.sectionHeader?.gospelProject?.trajectoria?.title
                  }
                  sectionHeaderTrajectoriaSubtitle={
                    data?.sectionHeader?.gospelProject?.trajectoria?.subtitle
                  }
                  boxImages={data?.gospelProject?.trajectoria?.images}
                  sectionHeaderProjectesRealitzatsTitle={
                    data?.sectionHeader?.gospelProject?.projectesRealitzats
                      ?.title
                  }
                  sectionHeaderProjectesRealitzatsSubtitle={
                    data?.sectionHeader?.gospelProject?.projectesRealitzats
                      ?.subtitle
                  }
                  sectionHeaderAgendaTitle={
                    data?.sectionHeader?.gospelProject?.agenda?.title
                  }
                  sectionHeaderAgendaSubtitle={
                    data?.sectionHeader?.gospelProject?.agenda?.subtitle
                  }
                  agendaData={agendaArray3}
                  defaultCoral={true}
                  width={360 * 5 + 40 * 4 + 130}
                  images={data?.gospelProject?.projectesRealitzats}
                  homeImages={data?.gospelProject?.home?.images}
                  filter={data?.agenda?.filter}
                />
              )}
              {data !== undefined && (
                <Footer
                  title={data?.gospelProject?.footer?.title}
                  contact={data?.gospelProject?.footer?.contact}
                  navbar={data?.navbar}
                />
              )}
            </>
          }
        />
        <Route
          path="/gospel-heaven-on-earth"
          element={
            <>
              {data !== undefined && (
                <DefaultCoral
                  logo={data?.gospelHOE?.trajectoria?.logo}
                  title={data?.navbar?.gospelHOE}
                  homeSubtitle={data?.gospelHOE?.home?.subtitle}
                  section={data?.gospelHOE?.trajectoria?.boxTitle}
                  textBox={data?.gospelHOE?.trajectoria?.boxText}
                  icon1Info={data?.gospelHOE?.trajectoria?.boxIconText1}
                  icon3Info={data?.gospelHOE?.trajectoria?.boxIconText3}
                  icon2Info={data?.gospelHOE?.trajectoria?.boxIconText2}
                  icon4Info={data?.gospelHOE?.trajectoria?.boxIconText4}
                  sectionHeaderTrajectoriaTitle={
                    data?.sectionHeader?.gospelHOE?.trajectoria?.title
                  }
                  sectionHeaderTrajectoriaSubtitle={
                    data?.sectionHeader?.gospelHOE?.trajectoria?.subtitle
                  }
                  boxImages={data?.gospelHOE?.trajectoria?.images}
                  sectionHeaderProjectesRealitzatsTitle={
                    data?.sectionHeader?.gospelHOE?.projectesRealitzats?.title
                  }
                  sectionHeaderProjectesRealitzatsSubtitle={
                    data?.sectionHeader?.gospelHOE?.projectesRealitzats
                      ?.subtitle
                  }
                  sectionHeaderAgendaTitle={
                    data?.sectionHeader?.gospelHOE?.agenda?.title
                  }
                  sectionHeaderAgendaSubtitle={
                    data?.sectionHeader?.gospelHOE?.agenda?.subtitle
                  }
                  agendaData={agendaArray1}
                  defaultCoral={true}
                  width={360 * 5 + 40 * 4 + 130}
                  images={data?.gospelHOE?.projectesRealitzats}
                  homeImages={data?.gospelHOE?.home?.images}
                  filter={data?.agenda?.filter}
                />
              )}
              {data !== undefined && (
                <Footer
                  title={data?.gospelHOE?.footer?.title}
                  contact={data?.gospelHOE?.footer?.contact}
                  navbar={data?.navbar}
                />
              )}
            </>
          }
        />
        <Route element={<ONG data={data} />} path="/ong" />
        <Route
          path="/agenda"
          element={
            <>
              <div
                style={
                  !isMobile
                    ? { padding: "var(--padding-full)" }
                    : { padding: "2rem", marginTop: "120px" }
                }
              >
                <SectionHeader
                  title={data?.sectionHeader?.agenda?.title}
                  text={data?.sectionHeader?.agenda?.subtitle}
                  width={"150px"}
                />
                <Agenda
                  data={showedAgendaArray}
                  main={true}
                  filter={data?.agenda?.filter}
                  setAgendaFilter={setAgendaFilter}
                  agendaFilter={agendaFilter}
                  nameGospelViu={data?.navbar?.gospelViu}
                  nameGospelProject={data?.navbar?.gospelProject}
                  nameGospelHOE={data?.navbar?.gospelHOE}
                />
              </div>
              <Footer
                title={data?.senseFronteres?.footer.title}
                contact={data?.senseFronteres?.footer?.contact}
                navbar={data?.navbar}
              />
            </>
          }
        />

        <Route />
      </Routes>
    </Router>
  );
};

export default RoutesComp;
