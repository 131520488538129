import React, { useState } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import "./Popup.css";

const Popup = ({ images, setDisplayPopup, currentIndex }) => {
  const [arrowCounter, setArrowCounter] = useState(currentIndex);
  const handleArrowClick = (left) => {
    if (left === true) {
      if (arrowCounter > 0) {
        setArrowCounter((prev) => prev - 1);
      } else return;
    } else if (left === false) {
      if (arrowCounter < images.length - 1) {
        setArrowCounter((prev) => prev + 1);
      } else return;
    }
  };
  console.log(images);
  return (
    <div
      className="popup__container"
      onClick={() => {
        setDisplayPopup(false);
      }}
    >
      <div className="popup__wrapper">
        <div className="popup__arrow" style={{ left: "5%" }}>
          <MdKeyboardArrowLeft
            color={"white"}
            size={35}
            onClick={(e) => {
              handleArrowClick(true);
              e.stopPropagation(); // Evita la propagació de l'esdeveniment
            }}
          />
        </div>
        <img
          src={images[arrowCounter].url}
          alt="popup"
          className="popup__image"
        />
        <div>
          <MdKeyboardArrowRight
            className="popup__arrow"
            color={"white"}
            size={35}
            style={{ right: "5%" }}
            onClick={(e) => {
              handleArrowClick(false);
              e.stopPropagation(); // Evita la propagació de l'esdeveniment
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Popup;
