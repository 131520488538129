import React from "react";
import "./PageInfoBoxItem.css";
const PageInfoBoxItem = ({ icon, top, bottom }) => {
  return (
    <div className="page-info-box-item__container">
      <div className="page-info-box-item__icon">{icon}</div>
      <div className="page-info-box-item__info">
        <p className="page-info-box-item__info__top">{top}</p>
        <p className="page-info-box-item__info__bottom">{bottom}</p>
      </div>
    </div>
  );
};

export default PageInfoBoxItem;
