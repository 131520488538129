/* eslint-disable */
import React, { useEffect, useState } from "react";
import "./DefaultCoralCarrusel.css";
import { MdKeyboardArrowUp } from "react-icons/md";
import { MdArrowDropDown } from "react-icons/md";
import useIsMobile from "../../../custom-hooks/useIsMobile";
const DefaultCoralCarrusel = ({ images, title, subtitle }) => {
  const [transformedImages, setTransformedImages] = useState([]);
  const isMobile = useIsMobile();
  useEffect(() => {
    const transformations = "q_80,f_auto,fl_lossy,c_scale,w_auto,dpr_auto";
    const transformed = images.map((image) => {
      const transformedUrl = image.url.replace(
        "/upload/",
        `/upload/${transformations}/`
      );
      return { ...image, url: transformedUrl };
    });
    setTransformedImages(transformed);
  }, [images]);
  const [imageIndex, setImageIndex] = useState(0);
  const handleImageIndexUp = () => {
    if (imageIndex === 0) {
      return;
    } else if (imageIndex > 0) {
      setImageIndex((prev) => prev - 1);
    }
  };
  const handleImageIndexDown = () => {
    if (imageIndex === images.length + 1) {
      return;
    } else if (images.length > imageIndex + 1) {
      setImageIndex((prev) => prev + 1);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (imageIndex < images.length - 1) {
        setImageIndex((prev) => prev + 1);
      } else {
        setImageIndex(0);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [imageIndex, images.length]);

  return (
    <div className="default-coral-carrusel__container">
      <div
        className="default-coral-carrusel__background"
        style={{
          opacity: imageIndex === 0 ? 0 : 0.5,
        }} /* Opacitat per al difuminat */
      ></div>
      {images !== undefined && transformedImages && (
        <img
          src={transformedImages[imageIndex]?.url}
          alt={"coral"}
          className="default-coral__image"
        />
      )}
      <div className="default-coral-carrusel__middle-box">
        <div className="default-coral-carrusel__text-wrapper">
          <span className="default-coral-carrusel__title">{title}</span>
          <span className="default-coral-carrusel__subtitle">{subtitle}</span>
        </div>

        <div className="default-coral-carrusel__ball-container">
          {images.map((item, index) => (
            <div
              key={index} // Assegura que cada element del map tingui una clau única
              className="default-coral-carrusel__ball"
              style={
                imageIndex === index
                  ? { background: "var(--ghospel-red)" }
                  : { background: "white" }
              }
            ></div>
          ))}
          {/* {!isMobile && (
            <div className="default-coral-carrusel__arrow-wrapper">
              <MdKeyboardArrowUp
                color="white"
                size={30}
                onClick={() => {
                  handleImageIndexUp();
                }}
                className="default-carrusel__arrow"
              />
              <MdArrowDropDown
                color="var(--ghospel-red)"
                size={35}
                onClick={() => {
                  handleImageIndexDown();
                }}
                className="default-carrusel__arrow"
              />
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default DefaultCoralCarrusel;
