import React from "react";
import "./HomeBox.css";

const HomeBox = ({ subtitle, title, text }) => {
  return (
    <div className="homebox__container">
      <div className="homebox__top-wrapper">
        <div className="homebox__subtitle-wrapper">
          <span className="homebox__subtitle">{subtitle}</span>
          <div className="homebox__subtitle-line"></div>
        </div>
        <span className="homebox__title">{title}</span>
      </div>
      <p className="homebox__text">{text}</p>
    </div>
  );
};

export default HomeBox;
