import { useState, useEffect } from "react";

function useIsMobile() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Defineix l'amplada màxima per considerar dispositius mòbils

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 768); // Actualitza l'estat segons l'amplada actual
    }

    window.addEventListener("resize", handleResize); // Afegeix el listener per a l'event resize

    return () => {
      window.removeEventListener("resize", handleResize); // Elimina el listener quan es desmunti el component
    };
  }, []);

  return isMobile;
}

export default useIsMobile;
