import React from "react";
import "./TicketsButton.css";
const TicketsButton = ({ buttonText, available, link }) => {
  return (
    <div
      className={"tickets-button__container"}
      style={{ backgroundColor: "var(--ghospel-red)" }}
      onClick={() => {
        window.open(link);
      }}
    >
      {buttonText}
    </div>
  );
};

export default TicketsButton;
