import React, { createContext, useState } from "react";

// Crea el context
const ArrowContext = createContext();

// Crea el component proveïdor
const ArrowContextProvider = ({ children }) => {
  const [arrowCounter, setArrowCounter] = useState(0);

  return (
    <ArrowContext.Provider value={{ arrowCounter, setArrowCounter }}>
      {children}
    </ArrowContext.Provider>
  );
};

export { ArrowContext, ArrowContextProvider };
