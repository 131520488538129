import React, { useEffect, useState } from "react";
import "./AgendaFilterButton.css";

const AgendaFilterButton = ({
  buttonText,
  setAgendaFilter,
  id,
  agendaFilter,
}) => {
  const [hovered, setHovered] = useState(false);
  const [styles, setStyles] = useState({});

  useEffect(() => {
    if (hovered && agendaFilter !== id) {
      setStyles({
        opacity: 0.7,
        cursor: "pointer",
      });
    } else if (hovered === false) {
      setStyles({
        opacity: 1,
      });
    } else if (hovered && agendaFilter === id) {
      setStyles({
        cursor: "pointer",
      });
    }
    //eslint-disable-next-line
  }, [hovered]);

  return (
    <div
      className={
        agendaFilter !== id
          ? "agenda-filter-button-container"
          : "agenda-filter-button-container filter-selected"
      }
      style={styles}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
      onClick={() => {
        setAgendaFilter(id);
      }}
    >
      {buttonText}
    </div>
  );
};

export default AgendaFilterButton;
