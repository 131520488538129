import { useEffect, useState } from "react";

function useElementHeight(elementRef) {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  useEffect(() => {
    // Funció per calcular l'alçada de l'element
    function updateHeight() {
      if (elementRef.current) {
        const newHeight = elementRef.current.clientHeight;
        const newWidth = elementRef.current.clientWidth;
        setWidth(newWidth);
        setHeight(newHeight);
      }
    }

    // Crida la funció per calcular l'alçada quan el component es munti i també quan el contingut de l'element canvii
    updateHeight();

    // Crida la funció de calcular l'alçada quan la mida de la finestra canvia
    // window.addEventListener("resize", updateHeight);

    // Esborra l'esdeveniment de l'escoltador quan el component es desmunta
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [elementRef]);

  return { height, width };
}

export default useElementHeight;
