import React from "react";
import "./PageInfoBox.css";
import useIsMobile from "../../../custom-hooks/useIsMobile";
//COMPONENTS
import PageInfoBoxItem from "../../utils/PageInfoBoxItem/PageInfoBoxItem";

//IMAGES
import { GiRoundTable } from "react-icons/gi";
import { TbBuildingCircus } from "react-icons/tb";
import { FaEuroSign } from "react-icons/fa";
import { HiMiniUsers } from "react-icons/hi2";
import HomeBox from "../HomeBox/HomeBox";

const PageInfoBox = ({ margin, data }) => {
  //eslint-disable-next-line
  const isMobile = useIsMobile();

  return (
    <div className="page-info-box__container" style={{ marginTop: margin }}>
      <div className="page-info-box__left-wrapper">
        <div className="page-info-box__left-wrapper__top-wrapper">
          {data !== undefined && (
            <PageInfoBoxItem
              icon={<GiRoundTable size={35} color={"var(--ghospel-red)"} />}
              top={data?.senseFronteres?.icons1?.number}
              bottom={data?.senseFronteres?.icons1?.text}
            />
          )}
          {data !== undefined && (
            <PageInfoBoxItem
              icon={<TbBuildingCircus size={35} color={"var(--ghospel-red)"} />}
              top={data?.senseFronteres?.icons2?.number}
              bottom={data?.senseFronteres?.icons2?.text}
            />
          )}
        </div>
        <div className="page-info-box__left-wrapper__bottom-wrapper">
          {data !== undefined && (
            <PageInfoBoxItem
              icon={<FaEuroSign size={35} color={"var(--ghospel-red)"} />}
              top={data?.senseFronteres?.icons3?.number}
              bottom={data?.senseFronteres?.icons3?.text}
            />
          )}
          {data !== undefined && (
            <PageInfoBoxItem
              icon={<HiMiniUsers size={35} color={"var(--ghospel-red)"} />}
              top={data?.senseFronteres?.icons4?.number}
              bottom={data?.senseFronteres?.icons4?.text}
            />
          )}
        </div>
      </div>
      <div className="page-info-box__right-wrapper">
        {data !== undefined && (
          <HomeBox
            subtitle={data?.senseFronteres?.senseFronteresSection?.titleBox}
            title={data?.senseFronteres?.senseFronteresSection?.textBox}
            text={data?.senseFronteres?.senseFronteresSection?.sectionBox}
          />
        )}
      </div>
    </div>
  );
};

export default PageInfoBox;
