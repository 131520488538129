import React, { useState, useEffect } from "react";
import "./AgendaDate.css";
import useIsMobile from "../../../custom-hooks/useIsMobile";

const AgendaDate = ({ buttonText }) => {
  const [styles, setStyles] = useState({
    padding: "12px",
    backgroundColor: "#FFD9D4",
    border: "1px solid black",
  });
  const isMobile = useIsMobile();

  useEffect(() => {
    if (isMobile === true) {
      setStyles({
        padding: "6px",
        backgroundColor: "#FFD9D4",
        border: "1px solid black",
      });
    } else {
      setStyles({
        padding: "12px",
        backgroundColor: "#FFD9D4",
        border: "1px solid black",
      });
    }
  }, [isMobile]);
  return (
    <div>
      <div className="agenda-date__container" style={styles}>
        {buttonText}
      </div>
    </div>
  );
};

export default AgendaDate;
