import React from "react";
import "./Agenda.css";

//COMPONENTS
import SectionHeader from "../../individual/SectionHeader/SectionHeader";
import AgendaFilter from "../../individual/AgendaFilter/AgendaFilter";
import AgendaItem from "../../individual/AgendaItem/AgendaItem";
import useIsMobile from "../../../custom-hooks/useIsMobile";

const Agenda = ({
  data,
  defaultCoral,
  home,
  main,
  sectionHeaderAgendaTitle,
  sectionHeaderAgendaSubtitle,
  filter,
  setAgendaFilter,
  agendaFilter,
  nameGospelViu,
  nameGospelProject,
  nameGospelHOE,
}) => {
  const isMobile = useIsMobile();

  return (
    <div
      className={
        !isMobile && main === true
          ? "agenda__container"
          : "agenda__container padding-none"
      }
      style={home === true && !isMobile ? { padding: "130px 150px" } : {}}
    >
      {home === true && (
        <SectionHeader
          title={sectionHeaderAgendaTitle}
          width={"283px"}
          text={sectionHeaderAgendaSubtitle}
        />
      )}
      {defaultCoral !== true && (
        <AgendaFilter
          items={filter}
          setAgendaFilter={setAgendaFilter}
          agendaFilter={agendaFilter}
        />
      )}
      <div
        className="agenda__items-wrapper"
        style={defaultCoral === true && !isMobile ? { marginTop: "80px" } : {}}
      >
        {data.map((item) => {
          return (
            <AgendaItem
              location={item?.location}
              title={item?.concert}
              id={item?.id}
              date={item?.date}
              available={item?.button?.available}
              buttonText={item?.buttonText}
              main={main}
              coral={item?.nom}
              link={item?.link}
              nameGospelViu={nameGospelViu}
              nameGospelProject={nameGospelProject}
              nameGospelHOE={nameGospelHOE}
              address={item.address}
              hora={item.hora}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Agenda;
